import React from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import { DomParser } from './domParser'
import getConfig from 'next/config'

const ImageContainer = styled.div`
  > div {
    height: ${props => props.theme.typography.pxToRem(400)};
    width: ${props => props.theme.typography.pxToRem(400)};
  }
`

const StyledImage = styled.img`
  height: ${props => props.theme.typography.pxToRem(400)};
  width: ${props => props.theme.typography.pxToRem(400)};
`

export const img: DomParser = (element, props) => {
  const imageSrc = props.src as string
  const imageAlt = props.alt as string
  const { images } = getConfig()

  const domainMatch = images.domains.some(domain => imageSrc.includes(domain))

  if (domainMatch) {
    return (
      <ImageContainer { ...props }>
        <Image src={imageSrc} alt={imageAlt} layout="fill" />
      </ImageContainer>
    )
  }

  return  (
    <StyledImage src={imageSrc} alt={imageAlt}/>
  )
}