import React from 'react'
import { Grid } from '@material-ui/core'
import { DomParser } from './domParser'
import { isTag } from 'domhandler/lib/node'
import { Button } from '../../components/button/button'

const freeformRow: DomParser = (element, props) => <Grid container spacing={6} { ...props } component={'div'} />

freeformRow.match = (element, props, utils) => utils.classes().includes('freeform-row')

const freeformColumn: DomParser = (element, props) => <Grid xs item { ...props } component={'div'} />

freeformColumn.match = (element, props, utils) => utils.classes().includes('freeform-column')

const radio: DomParser = (element, { children, ...props }) => {
  return (
    <>
      <input { ...props } />
      <span className={'fake-radio'} />
    </>
  )
}

radio.match = (element, props) => element.name === 'input' && props.type === 'radio'

const radioLabel: DomParser = (element, props) => {
  return (
    <label tabIndex={0} onKeyPress={e => { // eslint-disable-line
      if (e.code === 'Enter') {
        const input = e.currentTarget.querySelector('input')

        if (input) {
          input.checked = true
        }
      }
    }} className={'freeform-radio-label'} { ...props } />
  )
}

radioLabel.match = (element, props) => {
  if (element.name === 'label' && Object.keys(props).length === 1 && element.children.length > 0) {
    const firstChild = element.children[0]

    return (isTag(firstChild) && firstChild.attribs.type === 'radio')
  }

  return false
}

const submitButton: DomParser = (element, props) => <Button cta { ...props } />

submitButton.match = (element, props) => element.name === 'button' && props.type === 'submit'

export const forms = { freeformRow, freeformColumn, radio, radioLabel, submitButton }